<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-tabs v-model="activeTab" background-color="transparent">
          <v-tab class="font-weight-bold text-capitalize">
            {{ $_strings.mou.MOU }}
          </v-tab>
          <v-tab class="font-weight-bold text-capitalize">
            {{ $_strings.approval.APPROVAL_PAGE_NAME }}
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <v-tabs-items v-model="activeTab">
      <v-tab-item>
        <common-error
          v-if="isErrorMou"
          @fetchData="$emit('fetchWorkFlowData')"
        />
        <section v-else-if="isLoadingMou">
          <v-row v-for="skeleton in 10" :key="skeleton">
            <v-col cols="12" md="3">
              <v-skeleton-loader
                type="list-item"
              ></v-skeleton-loader>
            </v-col>
            <v-col cols="12" md="9">
              <v-skeleton-loader
                type="image"
                height="35"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
        </section>
        <Mou
          v-else
          :workflowId="workflowId"
          :workflowDetailId="workflowDetailId"
          @setWorkflowData="setWorkflowData"
          @submitRevise="submitRevise"
        />
      </v-tab-item>
      <v-tab-item>
        <Approval
          :workflowData="workflowData"
          @fetchWorkFlowData="fetchWorkFlowData"
        />
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import Mou from '../MoU/Create';
import Approval from './Approval.vue';

export default {

  name: 'MouInProcess',
  components: {
    Mou,
    Approval,
  },
  mounted() {
    const { data } = this.$route.params;
    if (data) {
      this.setWorkflowData(data);
    } else {
      this.fetchWorkFlowData();
    }
    if (+this.$route.query.tab > 1) {
      this.activeTab = 1;
    } else {
      this.activeTab = +this.$route.query.tab;
    }
  },
  data() {
    return {
      activeTab: 0,
      workflowData: {},
      workflowId: null,
      workflowDetailId: null,
      typeMoU: null,
      isErrorMou: false,
      isLoadingMou: false,
    };
  },
  methods: {
    setWorkflowData(workflowData) {
      const { workflowId, workflowDetailId } = workflowData;
      this.workflowData = workflowData;
      this.workflowId = workflowId;
      this.workflowDetailId = workflowDetailId;
    },
    async fetchWorkFlowData() {
      const { id } = this.$route.params;
      this.isLoadingMou = true;
      this.isErrorMou = false;
      try {
        const result = await this.$_services.mou.getDetailWorkflowMou(id);
        const { data } = result;
        this.setWorkflowData(data);
        this.$router.replace({
          query: {
            ...this.$route.query,
            m: Math.random(),
          },
          params: {
            ...this.$route.params,
            data,
          },
        });
      } catch {
        this.isErrorMou = true;
      } finally {
        this.isLoadingMou = false;
      }
    },
    // SUBMIT REVISE
    async submitRevise(mouBy, form) {
      const { workflowId, workflowDetailId } = this.workflowData;
      try {
        this.$root.$loading.show();
        await this.$_services.mou.reviseMou(mouBy, workflowId, workflowDetailId, form);
        this.$dialog.notify.success('Berhasil mengirim revisi');
        this.$router.push('/main/approval-inbox/list');
      } finally {
        this.$root.$loading.hide();
      }
    },
  },
};
</script>
