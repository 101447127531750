<template>
  <Mou />
</template>

<script>
import Mou from '../components/MouInProcess';

export default {

  name: 'MouInProcess',
  components: {
    Mou,
  },
};
</script>
